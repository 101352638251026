// @ts-nocheck
/* tslint-disable */
/* eslint-disable */
/**
 * SpaServer
 * SpaServer
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@xenoncloud.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { CleaningProtocol } from '../model/models';
import { Room } from '../model/models';
import { RoomIdResponse } from '../model/models';
import { RoomPhoneToken } from '../model/models';
import { RoomSession } from '../model/models';
import { RoomSessionUpgrade } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface ActivateRoomSessionUpgradeRequestParams {
    roomId: string;
    upgrade: string;
}

export interface FinishCleaningRequestParams {
    roomId: string;
    staffId?: string;
    notes?: string;
}

export interface GetRoomRequestParams {
    roomId: string;
}

export interface GetRoomActiveSessionRequestParams {
    roomId: string;
}

export interface GetRoomIdByDeviceIdRequestParams {
    deviceId: string;
}

export interface GetRoomPhoneTokenRequestParams {
    roomId: string;
}

export interface GetRoomSessionUpgradesRequestParams {
    roomId: string;
}

export interface GetRoomsRequestParams {
    page?: number;
    pageSize?: number;
}

export interface StartCleaningRequestParams {
    roomId: string;
    staffId: string;
}


@Injectable({
  providedIn: 'root'
})
export class RoomService {

    protected basePath = 'http://localhost/api/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * activate session upgrade
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public activateRoomSessionUpgrade(requestParameters: ActivateRoomSessionUpgradeRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<RoomSession>;
    public activateRoomSessionUpgrade(requestParameters: ActivateRoomSessionUpgradeRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<RoomSession>>;
    public activateRoomSessionUpgrade(requestParameters: ActivateRoomSessionUpgradeRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<RoomSession>>;
    public activateRoomSessionUpgrade(requestParameters: ActivateRoomSessionUpgradeRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const roomId = requestParameters.roomId;
        if (roomId === null || roomId === undefined) {
            throw new Error('Required parameter roomId was null or undefined when calling activateRoomSessionUpgrade.');
        }
        const upgrade = requestParameters.upgrade;
        if (upgrade === null || upgrade === undefined) {
            throw new Error('Required parameter upgrade was null or undefined when calling activateRoomSessionUpgrade.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (AdminKeyAuthorization) required
        credential = this.configuration.lookupCredential('AdminKeyAuthorization');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        // authentication (RoomAuthKeyAuthorization) required
        credential = this.configuration.lookupCredential('RoomAuthKeyAuthorization');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: this.encoder});
        }

        if (upgrade !== undefined) {
            formParams = formParams.append('upgrade', <any>upgrade) as any || formParams;
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<RoomSession>(`${this.configuration.basePath}/room/${encodeURIComponent(String(roomId))}/session/upgrades`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Finish Room Cleaning
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public finishCleaning(requestParameters: FinishCleaningRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CleaningProtocol>;
    public finishCleaning(requestParameters: FinishCleaningRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CleaningProtocol>>;
    public finishCleaning(requestParameters: FinishCleaningRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CleaningProtocol>>;
    public finishCleaning(requestParameters: FinishCleaningRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const roomId = requestParameters.roomId;
        if (roomId === null || roomId === undefined) {
            throw new Error('Required parameter roomId was null or undefined when calling finishCleaning.');
        }
        const staffId = requestParameters.staffId;
        const notes = requestParameters.notes;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (staffId !== undefined && staffId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>staffId, 'staffId');
        }
        if (notes !== undefined && notes !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>notes, 'notes');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (AdminKeyAuthorization) required
        credential = this.configuration.lookupCredential('AdminKeyAuthorization');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        // authentication (RoomAuthKeyAuthorization) required
        credential = this.configuration.lookupCredential('RoomAuthKeyAuthorization');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<CleaningProtocol>(`${this.configuration.basePath}/room/${encodeURIComponent(String(roomId))}/cleaning`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Room
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRoom(requestParameters: GetRoomRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Room>;
    public getRoom(requestParameters: GetRoomRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Room>>;
    public getRoom(requestParameters: GetRoomRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Room>>;
    public getRoom(requestParameters: GetRoomRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const roomId = requestParameters.roomId;
        if (roomId === null || roomId === undefined) {
            throw new Error('Required parameter roomId was null or undefined when calling getRoom.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (AdminKeyAuthorization) required
        credential = this.configuration.lookupCredential('AdminKeyAuthorization');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        // authentication (RoomAuthKeyAuthorization) required
        credential = this.configuration.lookupCredential('RoomAuthKeyAuthorization');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Room>(`${this.configuration.basePath}/room/${encodeURIComponent(String(roomId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get active Room session
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRoomActiveSession(requestParameters: GetRoomActiveSessionRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<RoomSession>;
    public getRoomActiveSession(requestParameters: GetRoomActiveSessionRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<RoomSession>>;
    public getRoomActiveSession(requestParameters: GetRoomActiveSessionRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<RoomSession>>;
    public getRoomActiveSession(requestParameters: GetRoomActiveSessionRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const roomId = requestParameters.roomId;
        if (roomId === null || roomId === undefined) {
            throw new Error('Required parameter roomId was null or undefined when calling getRoomActiveSession.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (AdminKeyAuthorization) required
        credential = this.configuration.lookupCredential('AdminKeyAuthorization');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        // authentication (RoomAuthKeyAuthorization) required
        credential = this.configuration.lookupCredential('RoomAuthKeyAuthorization');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<RoomSession>(`${this.configuration.basePath}/room/${encodeURIComponent(String(roomId))}/session`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Room
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRoomIdByDeviceId(requestParameters: GetRoomIdByDeviceIdRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<RoomIdResponse>;
    public getRoomIdByDeviceId(requestParameters: GetRoomIdByDeviceIdRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<RoomIdResponse>>;
    public getRoomIdByDeviceId(requestParameters: GetRoomIdByDeviceIdRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<RoomIdResponse>>;
    public getRoomIdByDeviceId(requestParameters: GetRoomIdByDeviceIdRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const deviceId = requestParameters.deviceId;
        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling getRoomIdByDeviceId.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (deviceId !== undefined && deviceId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>deviceId, 'deviceId');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<RoomIdResponse>(`${this.configuration.basePath}/room/byDeviceId`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Room
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRoomPhoneToken(requestParameters: GetRoomPhoneTokenRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<RoomPhoneToken>;
    public getRoomPhoneToken(requestParameters: GetRoomPhoneTokenRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<RoomPhoneToken>>;
    public getRoomPhoneToken(requestParameters: GetRoomPhoneTokenRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<RoomPhoneToken>>;
    public getRoomPhoneToken(requestParameters: GetRoomPhoneTokenRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const roomId = requestParameters.roomId;
        if (roomId === null || roomId === undefined) {
            throw new Error('Required parameter roomId was null or undefined when calling getRoomPhoneToken.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (AdminKeyAuthorization) required
        credential = this.configuration.lookupCredential('AdminKeyAuthorization');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        // authentication (RoomAuthKeyAuthorization) required
        credential = this.configuration.lookupCredential('RoomAuthKeyAuthorization');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<RoomPhoneToken>(`${this.configuration.basePath}/room/${encodeURIComponent(String(roomId))}/phone-token`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get session upgrade
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRoomSessionUpgrades(requestParameters: GetRoomSessionUpgradesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<RoomSessionUpgrade>>;
    public getRoomSessionUpgrades(requestParameters: GetRoomSessionUpgradesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<RoomSessionUpgrade>>>;
    public getRoomSessionUpgrades(requestParameters: GetRoomSessionUpgradesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<RoomSessionUpgrade>>>;
    public getRoomSessionUpgrades(requestParameters: GetRoomSessionUpgradesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const roomId = requestParameters.roomId;
        if (roomId === null || roomId === undefined) {
            throw new Error('Required parameter roomId was null or undefined when calling getRoomSessionUpgrades.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (AdminKeyAuthorization) required
        credential = this.configuration.lookupCredential('AdminKeyAuthorization');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        // authentication (RoomAuthKeyAuthorization) required
        credential = this.configuration.lookupCredential('RoomAuthKeyAuthorization');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<RoomSessionUpgrade>>(`${this.configuration.basePath}/room/${encodeURIComponent(String(roomId))}/session/upgrades`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Rooms
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRooms(requestParameters: GetRoomsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<Room>>;
    public getRooms(requestParameters: GetRoomsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<Room>>>;
    public getRooms(requestParameters: GetRoomsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<Room>>>;
    public getRooms(requestParameters: GetRoomsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const page = requestParameters.page;
        const pageSize = requestParameters.pageSize;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (pageSize !== undefined && pageSize !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>pageSize, 'pageSize');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (AdminKeyAuthorization) required
        credential = this.configuration.lookupCredential('AdminKeyAuthorization');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<Room>>(`${this.configuration.basePath}/room`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Start Room Cleaning
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public startCleaning(requestParameters: StartCleaningRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CleaningProtocol>;
    public startCleaning(requestParameters: StartCleaningRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CleaningProtocol>>;
    public startCleaning(requestParameters: StartCleaningRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CleaningProtocol>>;
    public startCleaning(requestParameters: StartCleaningRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const roomId = requestParameters.roomId;
        if (roomId === null || roomId === undefined) {
            throw new Error('Required parameter roomId was null or undefined when calling startCleaning.');
        }
        const staffId = requestParameters.staffId;
        if (staffId === null || staffId === undefined) {
            throw new Error('Required parameter staffId was null or undefined when calling startCleaning.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (AdminKeyAuthorization) required
        credential = this.configuration.lookupCredential('AdminKeyAuthorization');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        // authentication (RoomAuthKeyAuthorization) required
        credential = this.configuration.lookupCredential('RoomAuthKeyAuthorization');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: this.encoder});
        }

        if (staffId !== undefined) {
            formParams = formParams.append('staffId', <any>staffId) as any || formParams;
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<CleaningProtocol>(`${this.configuration.basePath}/room/${encodeURIComponent(String(roomId))}/cleaning`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
